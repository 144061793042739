import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'
function mobileNumber (value) {
  try {
        if (typeof value !== 'undefined' && value) {
           if (i18n.locale === 'bn') {
               return '০' + i18n.n(value, { useGrouping: false })
           } else {
            return '0' + i18n.n(value, { useGrouping: false })
        }
        }
  } catch (e) {
        return value
    }
}
const exportPdfDetails = async (reportTitle, thisObject, data, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      { text: reportTitle, style: 'header', alignment: 'center', decoration: 'underline' }
    ]

    const allRowsHead = [
        [
          { text: thisObject.$t('globalTrans.organization'), alignment: 'left', style: 'th' },
          { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search', colSpan: '3' },
          { },
          { }
      ],
      [
        { text: thisObject.$t('publication_management.pub_type'), alignment: 'left', style: 'th' },
        { text: i18n.locale === 'en' ? data.pub_type_name : data.pub_type_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('publication_management.pub_title'), alignment: 'left', style: 'th' },
        { text: i18n.locale === 'en' ? data.pub_title_name : data.pub_title_name_bn, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('external_research.publication_year'), alignment: 'left', style: 'th' },
        { text: thisObject.$n(data.circular.year_bn, { useGrouping: false }), alignment: 'left', style: 'search' },
        { text: thisObject.$t('publication_management.month_bn'), alignment: 'left', style: 'th' },
        { text: thisObject.getMonthBnName(data.circular.month_bn), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('external_research.circular_id'), alignment: 'left', style: 'th' },
        { text: data.circular.circular_auto_id, alignment: 'left', style: 'search' },
        { text: thisObject.$t('external_research.content_title'), alignment: 'left', style: 'th' },
        { text: data.content_title, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('publication_management.content_area'), alignment: 'left', style: 'th' },
        { text: i18n.locale === 'en' ? data.content_area_name : data.content_area_name_bn, alignment: 'left', style: 'search' },
        { },
        { }
      ],
      [
        { text: thisObject.$t('publication_management.writer_information'), alignment: 'left', style: 'th', colSpan: '4' },
        { },
        { },
        { }
      ],
      [
        { text: thisObject.$t('globalTrans.name'), alignment: 'left', style: 'th' },
        { text: thisObject.profile.name, alignment: 'left', style: 'search' },
        { text: thisObject.$t('globalUserData.mobile_no'), alignment: 'left', style: 'th' },
        { text: mobileNumber(thisObject.profile.mobile_no), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('globalTrans.email'), alignment: 'left', style: 'th' },
        { text: thisObject.profile.email, alignment: 'left', style: 'search' },
        { text: thisObject.$t('globalTrans.nid'), alignment: 'left', style: 'th' },
        { text: thisObject.profile.nid, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('globalUserData.gender'), alignment: 'left', style: 'th' },
        { text: thisObject.profile.gender, alignment: 'left', style: 'search' },
        { text: thisObject.$t('globalTrans.occupation'), alignment: 'left', style: 'th' },
        { text: thisObject.profile.occupation, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('globalTrans.address'), alignment: 'left', style: 'th' },
        { text: thisObject.profile.address, alignment: 'left', style: 'search', colSpan: '3' },
        { },
        { }
      ]
    ]

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['*', '*', '*', '*'],
          body: allRowsHead
        }
        // layout: {
        //   hLineWidth: function (i, node) {
        //     return 0
        //   },
        //   vLineWidth: function (i, node) {
        //     return 0
        //   }
        // }
      })
      if (data.honorariam) {
        const allRowsHead2 = [
          [
            { text: thisObject.$t('publication_management.honorarium_information'), bold: true, style: 'th', colSpan: '4', fillColor: '#dee2e6', alignment: 'center' },
            { },
            { },
            { }
          ],
          [
            { text: thisObject.$t('publication_management.honorarium_amount_total') + ' ' + thisObject.$t('publication_management.taka'), bold: true, style: 'td' },
            { text: thisObject.$n(data.honorariam.amount), style: 'td' },
            { text: thisObject.$t('publication_management.ait') + ' (%)', bold: true, style: 'td' },
            { text: thisObject.$n(data.honorariam.ait), style: 'td' }
          ],
          [
            { text: thisObject.$t('publication_management.revenue_stamp') + ' ' + thisObject.$t('globalTrans.taka'), bold: true, style: 'td' },
            { text: thisObject.$n(data.honorariam.revenue_stamp), style: 'td' },
            { text: thisObject.$t('publication_management.honorarium_net_amount') + ' ' + thisObject.$t('publication_management.taka'), bold: true, style: 'td' },
            { text: thisObject.$n(data.honorariam.net_amount), style: 'td' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 0,
            widths: ['*', '*', '*', '*'],
            body: allRowsHead2
          }
        })
      }
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          th2: {
            fillColor: '#dee2e6',
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 9 : 9,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 16,
            bold: 'Courier-Bold',
            margin: [0, 0, 0, 4]
          },
          header2: {
            fillColor: '#dee2e6',
            fontSize: 16,
            bold: 'Courier-Bold',
            italics: 'Courier-Oblique',
            bolditalics: 'Courier-BoldOblique',
            margin: [0, 10, 0, 10]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 15, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('circular')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
