<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row class="details">
                <b-col lg="12" sm="12">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" class="text-right mb-2">
                            <b-button @click="pdfExport()" variant="success">{{  $t('globalTrans.export_pdf') }}</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col md=12>
                                    <b-table-simple striped bordered small hover>
                                       <tbody>
                                           <b-tr>
                                                <b-td width="2%">{{ $t('globalTrans.organization') }}</b-td>
                                                <b-td width="6%" colspan="3">{{ (currentLocale == 'bn') ? data.org_name_bn : data.org_name }}</b-td>
                                            </b-tr>
                                             <b-tr>
                                                <b-td>{{$t('external_research.circular_id')}}</b-td>
                                                <b-td>{{ data.circular.circular_auto_id }}</b-td>
                                                <b-td>{{ $t('publication_management.pub_type') }}</b-td>
                                                <b-td>{{ (currentLocale == 'bn') ? data.pub_type_name_bn : data.pub_type_name }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('publication_management.pub_title')}}</b-td>
                                                <b-td>{{ (currentLocale == 'bn') ? data.pub_title_name_bn : data.pub_title_name }}</b-td>
                                                <b-td>{{ $t('external_research.content_title') }}</b-td>
                                                <b-td>{{ data.content_title }}</b-td>
                                            </b-tr>
                                            <!-- <b-tr>
                                                <b-td>{{$t('external_research.publication_year')}}</b-td>
                                                <b-td>{{ $n(data.circular.year_en, { useGrouping: false }) }}</b-td>
                                                <b-td>{{$t('publication_management.month_en')}}</b-td>
                                                <b-td>{{ getMonthName(data.circular.month_en) }}</b-td>
                                            </b-tr> -->
                                            <b-tr>
                                                <b-td>{{$t('external_research.publication_year')}}</b-td>
                                                <b-td>{{ $n(data.circular.year_bn, { useGrouping: false }) }}</b-td>
                                                <b-td>{{$t('publication_management.month_bn')}}</b-td>
                                                <b-td>{{ getMonthBnName(data.circular.month_bn) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('publication_management.content_area')}}</b-td>
                                                <b-td colspan="3">{{ (currentLocale == 'bn') ? data.content_area_name_bn : data.content_area_name }}</b-td>
                                            </b-tr>
                                             <b-tr>
                                                <b-td>{{$t('external_research.pub_content')}}</b-td>
                                                <b-td colspan="3">
                                                    <p v-html="data.content"></p>
                                                </b-td>
                                            </b-tr>
                                       </tbody>
                                    </b-table-simple>
                                     <b-table-simple striped bordered small hover>
                                       <tbody>
                                        <tr class="bg-light">
                                            <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                                            <th class="text-center">{{ $t('publication_management.document') }}</th>
                                        </tr>
                                       <b-tr v-for="(item, index) in data.documents" :key="index">
                                            <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                            <b-td class="text-center">
                                                    <a v-if="item.document" :href="url + 'uploads/research-farmer/' + item.document" target="_blank">
                                                        {{ item.document.split('/').pop() }}
                                                        <i class="ri-download-cloud-line" ></i>
                                                    </a>
                                            </b-td>
                                        </b-tr>
                                        </tbody>
                                    </b-table-simple>
                                    <b-row v-if="data.status === 1 || data.status === 3 || data.status === 4">
                                        <b-col lg="12" md="12" sm="12">
                                            <div class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                                {{ $t('globalTrans.remarks') }}
                                            </div>
                                            <p class="text-black">
                                                {{ data.remarks }}
                                            </p>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="12" md="12" sm="12">
                                            <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                                {{ $t('publication_management.writer_information') }}
                                            </p>
                                        </b-col>
                                    </b-row>
                                    <b-table-simple striped bordered small hover>
                                       <tbody>
                                           <b-tr>
                                                <b-td width="2%">{{ $t('globalTrans.name') }}</b-td>
                                                <b-td width="6%">{{ profile.name }}</b-td>
                                                <b-td width="2%">{{ $t('globalUserData.mobile_no') }}</b-td>
                                                <b-td width="6%">{{ profile.mobile_no | mobileNumber }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.email')}}</b-td>
                                                <b-td>{{ profile.email }}</b-td>
                                                <b-td>{{$t('globalTrans.nid')}}</b-td>
                                                <b-td>{{ profile.nid }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalUserData.gender')}}</b-td>
                                                <b-td>{{ profile.gender }}</b-td>
                                                <b-td>{{$t('globalTrans.occupation')}}</b-td>
                                                <b-td>{{ profile.occupation }}</b-td>
                                            </b-tr>
                                             <b-tr>
                                                <b-td>{{$t('globalTrans.address')}}</b-td>
                                                <b-td colspan="3">{{ profile.address }}</b-td>
                                            </b-tr>
                                       </tbody>
                                    </b-table-simple>
                                    <b-row v-if="data.honorariam">
                                        <b-col lg="12" md="12" sm="12">
                                            <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                                {{ $t('publication_management.honorarium_information') }}
                                            </p>
                                        </b-col>
                                    </b-row>
                                    <!-- <b-table-simple striped bordered small hover> -->
                                    <b-table-simple v-if="data.honorariam" striped bordered small hover>
                                       <tbody>
                                           <b-tr>
                                                <b-td width="2%">{{ $t('publication_management.honorarium_amount_total') +' '+ $t('publication_management.taka')}}</b-td>
                                                <b-td width="6%">{{ $n(data.honorariam.amount) }}</b-td>
                                                <b-td width="2%">{{ $t('publication_management.ait') +' (%)' }}</b-td>
                                                <b-td width="6%">{{ $n(data.honorariam.ait) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('publication_management.revenue_stamp') +' ' + $t('globalTrans.taka')}}</b-td>
                                                <b-td>{{ $n(data.honorariam.revenue_stamp) }}</b-td>
                                                <b-td>{{$t('publication_management.honorarium_net_amount') +' '+ $t('publication_management.taka')}}</b-td>
                                                <b-td>{{ $n(data.honorariam.net_amount) }}</b-td>
                                            </b-tr>
                                       </tbody>
                                    </b-table-simple>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.close') }}</b-button>
                                            </div>
                                        </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import ExportPdf from './export-pdf_details'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { profileListData } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
    props: ['id'],
    components: {
    },
    created () {
        if (this.id) {
            const tmp = this.getData()
            this.data = tmp
        }
         if (this.authUser.user_id) {
      this.loadAuthProfileData()
    }
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            url: agriResearchServiceBaseUrl,
            profile: {
                name: '',
                gender: '',
                occupation: '',
                email: '',
                mobile_no: '',
                nid: '',
                address: ''
              }
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        ...mapGetters({
        authUser: 'Auth/authUser'
    })
    },
    methods: {
        getData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
         loadAuthProfileData () {
        this.isLoading = true
        RestApi.getData(agriResearchServiceBaseUrl, profileListData, { user_id: this.authUser.user_id }).then(response => {
            if (response.success) {
                const data = response.data
                this.profile.name = this.$i18n.locale === 'bn' ? data.name_bn : data.name
                this.profile.gender = this.getGenderName(data.gender)
                this.profile.nid = data.nid
                this.profile.email = data.email
                this.profile.mobile_no = data.mobile_no
                this.profile.occupation = this.$i18n.locale === 'bn' ? data.occupation_bn : data.occupation
                this.profile.address = this.$i18n.locale === 'bn' ? data.address_bn : data.address_en
                this.isLoading = false
            } else {
                this.profile = {}
                this.isLoading = false
            }
        })
    },
     getGenderName (Id) {
        const Obj = this.$store.state.ExternalUserIrrigation.genderList.find(newItem => newItem.value === Id)
        if (this.$i18n.locale === 'bn') {
            return Obj.text_bn
        } else {
            return Obj.text_en
        }
    },
     getMonthName (monthId) {
            const objectData = this.$store.state.commonObj.monthList.find(type => type.value === monthId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
        getMonthBnName (monthId) {
            const objectData = this.$store.state.commonObj.banglaMonthList.find(type => type.value === monthId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
         pdfExport () {
            const reportTitle = this.$t('publication_management.circular') + ' ' + this.$t('globalTrans.details')
            ExportPdf.exportPdfDetails(reportTitle, this, this.data)
        }
    }
}

</script>
<style lang="scss">
    .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
