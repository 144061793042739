
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_sidebar.content_submission') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('publication_management.pub_type')"
                  label-for="pub_type"
                  >
                  <b-form-select
                  plain
                  v-model="search.pub_type_id"
                  :options="publicationTypeList"
                  id="pub_type"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('publication_management.pub_title')"
                  label-for="pub_title"
                  >
                  <b-form-select
                  plain
                  v-model="search.pub_title_id"
                  :options="publicationTitleList"
                  id="pub_title"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="pub_title_id"
              >
                <template v-slot:label>
                  {{$t('publication_management.content_area')}}
                </template>
                <b-form-select
                plain
                v-model="search.content_area_id"
                :options="publicationContentAreaList"
                id="pub_title_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
              </b-form-group>
        </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('external_sidebar.content_submission_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(year_month)="data">
                      <span>{{ $n(data.item.circular.year_bn, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-danger" v-if="data.item.flag == 0">{{ $t('globalTrans.draft') }}
                          <span v-if="data.item.status === 1">{{ $t('globalTrans.and') + ' ' + $t('globalTrans.returned') }}</span>
                      </span>
                      <span v-else>
                        <span v-if="data.item.status == 0">
                          <span class="badge badge-info" v-if="data.item.is_recommended == 1">
                            {{ $t('globalTrans.recommended') }}
                          </span>
                          <span class="badge badge-warning" v-else>
                              {{$t('globalTrans.processing')}}
                          </span>
                        </span>
                        <span class="badge badge-danger" v-else-if="data.item.status == 1">{{$t('globalTrans.returned')}}</span>
                        <span class="badge badge-danger" v-else-if="data.item.status == 3">{{$t('globalTrans.rejected')}}</span>
                        <span class="badge badge-warning" v-else-if="data.item.status == 4">{{ $t('globalTrans.re_submitted') }}</span>
                        <span class="badge badge-success" v-else>
                          <span v-if="data.item.honorarium_pay_status === 0">{{$t('globalTrans.approved') + ' ' + $t('globalTrans.and') + ' ' + $t('globalTrans.unpaid')}}</span>
                          <span v-else>{{$t('globalTrans.approved') + ' ' + $t('globalTrans.and') + ' ' + $t('globalTrans.paid')}}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="View Details" v-b-modal.modal-5 variant=" iq-bg-success mr-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button v-if="data.item.flag === 0 || data.item.status == 1" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="xl" :title="viewTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form.vue'
import Details from './Details.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalSubmissionListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV, Details
  },
  data () {
    return {
        editItemId: 0,
        search: {
            pub_title_id: 0,
            pub_type_id: 0,
            content_area_id: 0
        },
        publicationTitleList: []
    }
  },
  created () {
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    publicationContentAreaList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationContentAreaList.filter(type => type.status === 1).map(item => {
          return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    publicationTypeList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTypeList.filter(itm => itm.status === 1).map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    formTitle () {
       return this.$t('external_sidebar.publication_proposal_submission') + ' ' + this.$t('globalTrans.entry')
    },
    viewTitle () {
       return this.$t('publication_management.circular') + ' ' + this.$t('globalTrans.details')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('publication_management.circular_subject'), class: 'text-left' },
          { label: this.$t('publication_management.pub_type'), class: 'text-left' },
          { label: this.$t('publication_management.pub_title'), class: 'text-left' },
          { label: this.$t('publication_management.content_area'), class: 'text-left' },
          { label: this.$t('external_research.publication_year'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'circular.circular_subject_bn' },
          { key: 'pub_type_name_bn' },
          { key: 'pub_title_name_bn' },
          { key: 'content_area_name_bn' },
          { key: 'year_month' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'circular.circular_subject' },
          { key: 'pub_type_name' },
          { key: 'pub_title_name' },
          { key: 'content_area_name' },
          { key: 'year_month' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.pub_type_id': function (newVal) {
      this.getPublicationTitleList()
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.pub_type_id) {
                this.getPublicationTitleList()
            }
        }
    }
  },
  mounted () {
    core.index()
    this.loadData()
    flatpickr('#to_date', {})
    flatpickr('#from_date', {})
  },
  methods: {
    getPublicationTitleList () {
        this.publicationTitleList = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === this.search.pub_type_id).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    async searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    view (item) {
      this.editItemId = item.id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, proposalSubmissionListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpOrg = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(newItem => newItem.value === item.circular.org_id)
          const tmpType = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTypeList.find(newItem => newItem.value === item.circular.pub_type_id)
          const tmpTitle = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.find(newItem => newItem.value === item.circular.pub_title_id)
          const tmpContentArea = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationContentAreaList.find(newItem => newItem.value === parseInt(item.content_area_id))
          const newData = {
              org_name: tmpOrg !== undefined ? tmpOrg.text_en : '',
              org_name_bn: tmpOrg !== undefined ? tmpOrg.text_bn : '',
              pub_type_name: tmpType !== undefined ? tmpType.text_en : '',
              pub_type_name_bn: tmpType !== undefined ? tmpType.text_bn : '',
              pub_title_name: tmpTitle !== undefined ? tmpTitle.text_en : '',
              pub_title_name_bn: tmpTitle !== undefined ? tmpTitle.text_bn : '',
              content_area_name: tmpContentArea !== undefined ? tmpContentArea.text_en : '',
              content_area_name_bn: tmpContentArea !== undefined ? tmpContentArea.text_bn : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
